@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GlacialIndifference-bold";
  src: url("../public/fonts/GlacialIndifference-Bold.otf");
}
@font-face {
  font-family: "GlacialIndifference";
  src: url("../public/fonts/GlacialIndifference-Regular.otf");
}

@layer utilities {
  .square {
    aspect-ratio: 2 / 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "GlacialIndifference",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
